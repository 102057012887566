<style scoped></style>
<template>
  <div>
    <v-card elevation="5">
      <v-card-text class="pa-0 font-weight-bold">
        <div>
          <div
            class="primary_2 d-flex  white--text font-weight-bold px-2 mb-1"
          >
            <div
              style="width: 75%"
              @click="togglePersonalDetailsAccordion()"
              class="d-flex align-center py-3"
            >
              <div class="text-body-1 font-weight-medium px-3">
                {{ personal_details_accordion.title }}
              </div>
            </div>
            <div style="width: 25%" class="d-flex align-center justify-end">
              <v-btn
                outlined
                class="white--text mr-2"
                small
                @click="editPersonalDetail()"
                ><v-icon small>mdi-pencil</v-icon>Edit</v-btn
              >
              <v-btn fab small text @click="toggleBasicDetailsAccordion()">
                <v-icon color="white" v-if="personal_details_accordion.show">
                  mdi-chevron-up
                </v-icon>
                <v-icon color="white" v-else>
                  mdi-chevron-down
                </v-icon>
              </v-btn>
            </div>
          </div>
          <div v-if="personal_details_accordion.show">
            <div class="pb-6 px-3 pt-3">
              <v-row class="pl-3 pt-0">
                <v-col cols="4">
                  <p class="field-title">
                    Father's Name
                  </p>
                  <p class="field-content">
                    {{ this.personal_details.fathers_name }}
                  </p>
                </v-col>
                <v-col cols="4">
                  <p class="field-title">
                    Mother's Name
                  </p>
                  <p class="field-content">
                    {{ this.personal_details.mothers_name }}
                  </p>
                </v-col>
                <v-col cols="4">
                  <p class="field-title">
                    DOB
                  </p>
                  <p class="field-content">
                    {{ this.personal_details.date_of_birth }}
                  </p>
                </v-col>
              </v-row>
              <v-row class="pl-3 pt-0">
                <v-col cols="4">
                  <p class="field-title">
                    Education
                  </p>
                  <p class="field-content">
                    {{ this.personal_details.education }}
                  </p>
                </v-col>
                <!-- <v-col cols="4">
                  <p class="field-title">
                    Business Registration
                  </p>
                  <p class="field-content">
                    Registration
                  </p>
                </v-col>
                <v-col cols="4">
                  <p class="field-title">
                    Loan Purpose
                  </p>
                  <p class="field-content">
                    Purpose
                  </p>
                </v-col> -->
              </v-row>
              <v-row
                class="pl-3 pt-2 text-body-1 font-weight-bold primary_2--text"
              >
                <v-col class="pb-0">
                  CURRENT ADDRESS
                </v-col>
              </v-row>
              <v-row class="pl-3 pt-0">
                <v-col cols="4">
                  <p class="field-title">
                    House No / Building Name
                  </p>
                  <p class="field-content">
                    {{ this.current_address.address_details }}
                  </p>
                </v-col>
                <v-col cols="4">
                  <p class="field-title">
                    Street Name / Landmark
                  </p>
                  <p class="field-content">
                    {{ this.current_address.landmark }}
                  </p>
                </v-col>
                <v-col cols="4">
                  <p class="field-title">
                    Area
                  </p>
                  <p class="field-content">
                    {{ this.current_address.area }}
                  </p>
                </v-col>
              </v-row>
              <v-row class="pl-3 pt-0">
                <v-col cols="4">
                  <p class="field-title">
                    Pincode
                  </p>
                  <p class="field-content">
                    {{ this.current_address.pincode }}
                  </p>
                </v-col>
                <v-col cols="4">
                  <p class="field-title">
                    City
                  </p>
                  <p class="field-content">
                    {{ this.current_address.city }}
                  </p>
                </v-col>
                <v-col cols="4">
                  <p class="field-title">
                    State
                  </p>
                  <p class="field-content">
                    {{ this.current_address.state }}
                  </p>
                </v-col>
              </v-row>
              <v-row
                class="pl-3 pt-2 text-body-1 font-weight-bold primary_2--text"
              >
                <v-col class="pb-0">
                  PERMANENT ADDRESS
                </v-col>
              </v-row>
              <v-row class="pl-3 pt-0">
                <v-col cols="4">
                  <p class="field-title">
                    House No / Building Name
                  </p>
                  <p class="field-content">
                    {{ this.permanent_address.address_details }}
                  </p>
                </v-col>
                <v-col cols="4">
                  <p class="field-title">
                    Street Name / Landmark
                  </p>
                  <p class="field-content">
                    {{ this.permanent_address.landmark }}
                  </p>
                </v-col>
                <v-col cols="4">
                  <p class="field-title">
                    Area
                  </p>
                  <p class="field-content">
                    {{ this.permanent_address.area }}
                  </p>
                </v-col>
              </v-row>
              <v-row class="pl-3 pt-0">
                <v-col cols="4">
                  <p class="field-title">
                    Pincode
                  </p>
                  <p class="field-content">
                    {{ this.permanent_address.pincode }}
                  </p>
                </v-col>
                <v-col cols="4">
                  <p class="field-title">
                    City
                  </p>
                  <p class="field-content">
                    {{ this.permanent_address.city }}
                  </p>
                </v-col>
                <v-col cols="4">
                  <p class="field-title">
                    State
                  </p>
                  <p class="field-content">
                    {{ this.permanent_address.state }}
                  </p>
                </v-col>
              </v-row>
              <v-row class="pl-3 pt-0">
                <v-col cols="4">
                  <p class="field-title">
                    No. of years in Current City
                  </p>
                  <p class="field-content">
                    {{ this.personal_details.no_of_years_in_current_city }}
                  </p>
                </v-col>
                <v-col cols="4">
                  <p class="field-title">
                    No. of years in Current Address
                  </p>
                  <p class="field-content">
                    {{ this.personal_details.no_of_years_in_current_address }}
                  </p>
                </v-col>
                <!-- <v-col cols="4">
                  <p class="field-title">
                    State
                  </p>
                  <p class="field-content">
                    Chhattisgarh
                  </p>
                </v-col> -->
              </v-row>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <edit-personal-detail
      :personal_detail="personal_detail_dialog"
      @reload="getPersonalDetails"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import editPersonalDetail from "../dialog/editPersonalDetail.vue";

export default {
  components: {
    // LoanTable: () => import("@/components/customer/LoanTable"),
    editPersonalDetail,
  },
  data() {
    return {
      //   personal_details_accordion: {
      //     id: 1,
      //     title: "Basic Details",
      //     show: true,
      //   },

      are_all_collapsed: false,

      active_loan_id: null,
      is_other_doc_required: false,
      is_other_doc_verified: false,
      is_active_loan_available: false,
      personal_details: [],
      permanent_address: [],
      current_address: [],
      personal_detail_dialog: {
        personal_info: {},
        id: "",
        flag: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      personal_details_accordion: "business_loan/getPersonalDetailsAccordion",
    }),
  },
  mounted() {
    this.getPersonalDetails();
  },
  methods: {
    togglePersonalDetailsAccordion() {
      //   this.personal_details_accordion.show = !this.personal_details_accordion.show;
      this.$store.dispatch("business_loan/setPersonalDetailsAccordion");
      //   console.log(this.personal_details_accordion);
    },
    editPersonalDetail() {
      this.personal_detail_dialog.flag = true;
      this.personal_detail_dialog.personal_info = this.personal_details;
      this.personal_detail_dialog.id = this.decrypt(this.$route.params.id);
    },

    getPersonalDetails() {
      const self = this;
      let look_up_key = `${self.decrypt(this.$route.params.id)}/`;
      const successHandler = (response) => {
        // console.log("pers", response);
        this.personal_details = response.data.result;
        this.current_address = response.data.result.current_address;
        this.permanent_address = response.data.result.permanent_address;
      };
      const finallyHandler = () => {};
      self.GET_request(
        self,
        self.$urls.GET_BUSINESS_LOAN_PERSONAL_DETAILS,
        look_up_key,
        null,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
  },
};
</script>
<style scoped>
.field-title {
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.681);
}
.field-content {
  font-size: 14px;
  color: #000000;
  font-weight: 700;
}
</style>
